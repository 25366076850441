import React from "react";

import { graphql } from "gatsby";
import AppBase from "../components/AppBase";
import BlogArticle from "../components/BlogArticle";
import { Helmet } from "react-helmet";

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const {
    frontmatter: { title, url, date, categories },
    fields: { pageType },
    html
  } = markdownRemark;
  return (
    <AppBase>
      <Helmet>
        <title>
          {"Jake's Blog"} - {title}
        </title>
      </Helmet>
      <BlogArticle
        title={title}
        url={url}
        date={date}
        bodyHtml={html}
        showReadMore={false}
        categories={categories}
        fullArticle={true}
        blogPost={pageType === "markdown-posts"}
      />
    </AppBase>
  );
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { url: { eq: $path } }) {
      html
      fields {
        pageType
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        url
        title
        categories
      }
    }
  }
`;
